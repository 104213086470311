import { hasFeatureAccess } from 'app/admin/helpers/AccessHelper';
import type { User } from 'app/auth/types/user';
import { ConnectionTypes } from 'app/settings/constants/ConnectionConstants';
import { filterConnectionList } from 'app/settings/Helper';
import {
	HELP_CENTER_ANALYZE_PAGE,
	HELP_CENTER_CLEANSE_PAGE,
	HELP_CENTER_DISCOVER_PAGE,
	HELP_CENTER_ENRICH_PAGE,
	HELP_CENTER_PREVENT_PAGE,
	HELP_CENTER_ROUTE_PAGE,
} from 'app/utils/HelpCenterConstants';

import { ApplicationTypes, TaskTypes } from '../constants/TaskConstants';
import type { MassUpdate, Task, TaskType, WebSubmissionTask } from '../types/taskTypes';

export function resolveAPIUrlByTaskType(type: TaskType | 'round_robin') {
	switch (type) {
		case TaskTypes.DEDUPLICATION:
			return 'deduplication';
		case TaskTypes.LIST_IMPORT:
			return 'unique_upload';
		case TaskTypes.WEB_SUBMISSION:
			return 'webtolead';
		case TaskTypes.ENRICHMENT_API:
			return 'webapi';
		case TaskTypes.UE_TASK:
			return 'unique_entry';
		case TaskTypes.ENRICHMENT:
			return 'enrichment';
		case TaskTypes.NORMALIZATION:
			return 'normalization';
		case TaskTypes.MASS_DELETE:
			return 'mass_delete';
		case TaskTypes.MASS_UPDATE:
			return 'mass_update';
		case TaskTypes.INTELLIGENT_FORMS:
			return 'intelligent-forms';
		case TaskTypes.DISCOVERY:
			return 'discovery';
		case TaskTypes.DATA_QUALITY_EMPTY:
		case TaskTypes.DATA_QUALITY_SCORE:
			return 'data_quality';
		case TaskTypes.TOP_OFF:
			return 'top_off';
		case 'round_robin':
			return 'roundrobin';
		default:
			return null;
	}
}

export function resolveTaskTypeByUrl(url: string) {
	const parsedUrl = url.replace('enrichment-report', '');

	if (parsedUrl.includes('deduplication')) {
		return TaskTypes.DEDUPLICATION;
	}

	if (parsedUrl.includes('mass-delete')) {
		return TaskTypes.MASS_DELETE;
	}

	if (parsedUrl.includes('mass-update')) {
		return TaskTypes.MASS_UPDATE;
	}

	if (parsedUrl.includes('list-import')) {
		return TaskTypes.LIST_IMPORT;
	}

	if (parsedUrl.includes('web-submission')) {
		return TaskTypes.WEB_SUBMISSION;
	}

	if (parsedUrl.includes('webapi')) {
		return TaskTypes.ENRICHMENT_API;
	}

	if (parsedUrl.includes('unique-entry')) {
		return TaskTypes.UE_TASK;
	}

	if (parsedUrl.includes('intelligent_forms')) {
		return TaskTypes.INTELLIGENT_FORMS;
	}

	if (parsedUrl.includes('enrichment')) {
		return TaskTypes.ENRICHMENT;
	}

	if (parsedUrl.includes('normalization')) {
		return TaskTypes.NORMALIZATION;
	}

	if (parsedUrl.includes('discovery')) {
		return TaskTypes.DISCOVERY;
	}

	if (parsedUrl.includes('top-off')) {
		return TaskTypes.TOP_OFF;
	}

	return null;
}

export function resolveFrontendUrlByTaskType(type: TaskType) {
	switch (type) {
		case TaskTypes.DEDUPLICATION:
			return 'deduplication';
		case TaskTypes.LIST_IMPORT:
			return 'list-import';
		case TaskTypes.WEB_SUBMISSION:
			return 'web-submission';
		case TaskTypes.ENRICHMENT_API:
			return 'webapi';
		case TaskTypes.UE_TASK:
			return 'unique-entry';
		case TaskTypes.ENRICHMENT:
			return 'enrichment';
		case TaskTypes.NORMALIZATION:
			return 'normalization';
		case TaskTypes.MASS_DELETE:
			return 'mass-delete';
		case TaskTypes.MASS_UPDATE:
			return 'mass-update';
		case TaskTypes.INTELLIGENT_FORMS:
			return 'intelligent_forms';
		case TaskTypes.DISCOVERY:
			return 'discovery/searches';
		case TaskTypes.TOP_OFF:
			return 'routing/top-off';
		default:
			return null;
	}
}

export function resolveTaskPageContext(type: TaskType): [string, [string | { label: string; url: string }, string]] {
	switch (type) {
		case TaskTypes.DATA_QUALITY_SCORE:
			return ['Data Quality Score', [HELP_CENTER_ANALYZE_PAGE, 'Data Quality Score']];
		case TaskTypes.DATA_QUALITY_EMPTY:
			return ['Data Quality Empty', [HELP_CENTER_ANALYZE_PAGE, 'Data Quality Empty']];
		case TaskTypes.NORMALIZATION:
			return ['Normalize/Segment', [HELP_CENTER_CLEANSE_PAGE, 'Normalize/Segment']];
		case TaskTypes.DEDUPLICATION:
			return ['Deduplicate', [HELP_CENTER_CLEANSE_PAGE, 'Deduplicate']];
		case TaskTypes.MASS_DELETE:
			return ['Mass Delete', [HELP_CENTER_CLEANSE_PAGE, 'Mass Delete']];
		case TaskTypes.MASS_UPDATE:
			return ['Mass Update', [HELP_CENTER_CLEANSE_PAGE, 'Mass Update']];
		case TaskTypes.LIST_IMPORT:
			return ['List Import', [HELP_CENTER_PREVENT_PAGE, 'List Import']];
		case TaskTypes.WEB_SUBMISSION:
			return ['Web Submission', [HELP_CENTER_PREVENT_PAGE, 'Web Submission']];
		case TaskTypes.ENRICHMENT:
			return ['Mass Enrich', [HELP_CENTER_ENRICH_PAGE, 'Mass Enrich']];
		case TaskTypes.ENRICHMENT_API:
			return ['Enrich API', [HELP_CENTER_ENRICH_PAGE, 'Enrich API']];
		case TaskTypes.INTELLIGENT_FORMS:
			return ['Intelligent Forms', [HELP_CENTER_ENRICH_PAGE, 'Intelligent Forms']];
		case TaskTypes.UE_TASK:
			return ['Unique Entry Enrich', [HELP_CENTER_ENRICH_PAGE, 'Unique Entry Enrich']];
		case TaskTypes.DISCOVERY:
			return ['Searches', [HELP_CENTER_DISCOVER_PAGE, 'Searches']];
		case TaskTypes.TOP_OFF:
			return ['Top Off Queue', [HELP_CENTER_ROUTE_PAGE, 'Top Off Queue']];
		default:
			return ['', ['', '']];
	}
}

export function resolveProcessAllFeatureByTask(task: Task) {
	switch (task.task_type) {
		case TaskTypes.DEDUPLICATION:
			if (task.connection.connection_type === ConnectionTypes.SALESFORCE) {
				return !['Lead', 'Contact', 'Account', 'Individual'].includes(task.source_object)
					? 'CUSTOM_OBJECT_MERGE_ALL'
					: 'DEDUPLICATION_PROCESS_ALL';
			}

			return `${task.connection.connection_type.toUpperCase()}_DEDUPLICATION_PROCESS_ALL`;
		case TaskTypes.LIST_IMPORT:
			return 'LIST_IMPORT_PROCESS_ALL';
		case TaskTypes.ENRICHMENT:
			return 'ENRICHMENT_PROCESS_ALL';
		case TaskTypes.NORMALIZATION:
			return 'NORMALIZATION_PROCESS_ALL';
		case TaskTypes.MASS_DELETE:
			return 'MASS_DELETE_PROCESS_ALL';
		case TaskTypes.MASS_UPDATE:
			return 'MASS_UPDATE_PROCESS_ALL';
		case TaskTypes.DISCOVERY:
			return 'DISCOVER_PROCESS_ALL';
		default:
			return null;
	}
}

export function resolveProcessSingleFeatureByTask(task: Task) {
	switch (task.task_type) {
		case TaskTypes.DEDUPLICATION:
			if (task.connection.connection_type === ConnectionTypes.SALESFORCE) {
				return !['Lead', 'Contact', 'Account', 'Individual'].includes(task.source_object)
					? 'CUSTOM_OBJECT_MERGED_RECORDS'
					: 'DEDUPLICATION_PROCESS_RECORDS';
			}

			return `${task.connection.connection_type.toUpperCase()}_DEDUPLICATION_MERGED_RECORDS`;
		case TaskTypes.LIST_IMPORT:
			return 'LIST_IMPORT_PROCESSED_RECORDS';
		case TaskTypes.ENRICHMENT:
			return 'ENRICHMENT_PROCESSED_RECORDS';
		case TaskTypes.NORMALIZATION:
			return 'NORMALIZATION_PROCESSED_RECORDS';
		case TaskTypes.MASS_DELETE:
			return 'MASS_DELETE_PROCESSED_RECORDS';
		case TaskTypes.MASS_UPDATE:
			return 'MASS_UPDATE_PROCESSED_RECORDS';
		default:
			return null;
	}
}

export function resolvePermissionNaturalKeyByTaskType(type: TaskType) {
	switch (type) {
		case TaskTypes.DEDUPLICATION:
			return 'deduplicate_task';
		case TaskTypes.LIST_IMPORT:
			return 'list_import_task';
		case TaskTypes.WEB_SUBMISSION:
			return 'web_submission_task';
		case TaskTypes.ENRICHMENT_API:
			return 'enrich_task';
		case TaskTypes.UE_TASK:
			return 'unique_entry_task';
		case TaskTypes.ENRICHMENT:
			return 'enrich_task';
		case TaskTypes.NORMALIZATION:
			return 'normalize/segment_task';
		case TaskTypes.MASS_DELETE:
			return 'mass_delete_task';
		case TaskTypes.MASS_UPDATE:
			return 'mass_update_task';
		case TaskTypes.INTELLIGENT_FORMS:
			return 'intelligent_form';
		case TaskTypes.TOP_OFF:
			return 'top_off_queue';
		case TaskTypes.DISCOVERY:
			return 'discovery_task';
		case TaskTypes.DATA_QUALITY_SCORE:
		case TaskTypes.DATA_QUALITY_EMPTY:
			return 'field_analysis';
		default:
			return null;
	}
}

export function resolveResolutionNaturalKeyByTaskType(type: TaskType) {
	switch (type) {
		case TaskTypes.DEDUPLICATION:
			return 'deduplicate_task_cluster';
		case TaskTypes.LIST_IMPORT:
			return 'list_import_task_record';
		case TaskTypes.WEB_SUBMISSION:
			return 'web_submission_task_record';
		case TaskTypes.ENRICHMENT_API:
			return 'enrich_api_task_record';
		case TaskTypes.UE_TASK:
			return 'unique_entry_task_record';
		case TaskTypes.ENRICHMENT:
			return 'enrich_task_record';
		case TaskTypes.NORMALIZATION:
			return 'normalize/segment_task_record';
		case TaskTypes.MASS_DELETE:
			return 'mass_delete_task_record';
		case TaskTypes.MASS_UPDATE:
			return 'mass_update_task_record';
		case TaskTypes.TOP_OFF:
			return 'top_off_queue_record';
		default:
			return null;
	}
}

export function connectionTypesByTask(user: User, taskType: TaskType) {
	switch (taskType) {
		case TaskTypes.DEDUPLICATION:
			return filterConnectionList(
				[
					ConnectionTypes.SALESFORCE,
					ConnectionTypes.MARKETO,
					ConnectionTypes.ELOQUA,
					ConnectionTypes.HUBSPOT,
					ConnectionTypes.DYNAMICS,
				],
				user
			).filter(connectionType => hasFeatureAccess(user, [`${connectionType.toUpperCase()}_DEDUPLICATION`], null));
		case TaskTypes.TOP_OFF:
		case TaskTypes.UE_TASK:
			return filterConnectionList([ConnectionTypes.SALESFORCE], user);
		case TaskTypes.ENRICHMENT:
			return filterConnectionList(
				[
					ConnectionTypes.SALESFORCE,
					ConnectionTypes.MARKETO,
					ConnectionTypes.PARDOT,
					ConnectionTypes.ELOQUA,
					ConnectionTypes.HUBSPOT,
					ConnectionTypes.DYNAMICS,
					ConnectionTypes.SNOWFLAKE,
				],
				user
			).filter(connectionType => hasFeatureAccess(user, [`${connectionType.toUpperCase()}_ENRICHMENT`], null));
		case TaskTypes.NORMALIZATION:
			return filterConnectionList(
				[
					ConnectionTypes.SALESFORCE,
					ConnectionTypes.MARKETO,
					ConnectionTypes.PARDOT,
					ConnectionTypes.ELOQUA,
					ConnectionTypes.HUBSPOT,
					ConnectionTypes.DYNAMICS,
				],
				user
			).filter(connectionType => hasFeatureAccess(user, [`${connectionType.toUpperCase()}_NORMALIZATION`], null));
		case TaskTypes.MASS_DELETE:
			return filterConnectionList(
				[
					ConnectionTypes.SALESFORCE,
					ConnectionTypes.MARKETO,
					ConnectionTypes.PARDOT,
					ConnectionTypes.ELOQUA,
					ConnectionTypes.HUBSPOT,
					ConnectionTypes.DYNAMICS,
				],
				user
			).filter(connectionType => hasFeatureAccess(user, [`${connectionType.toUpperCase()}_MASS_DELETE`], null));
		case TaskTypes.MASS_UPDATE:
			return filterConnectionList(
				[
					ConnectionTypes.SALESFORCE,
					ConnectionTypes.MARKETO,
					ConnectionTypes.PARDOT,
					ConnectionTypes.ELOQUA,
					ConnectionTypes.HUBSPOT,
					ConnectionTypes.DYNAMICS,
				],
				user
			).filter(connectionType => hasFeatureAccess(user, [`${connectionType.toUpperCase()}_MASS_UPDATE`], null));
		case TaskTypes.LIST_IMPORT:
			return filterConnectionList(
				[ConnectionTypes.SALESFORCE, ConnectionTypes.ELOQUA, ConnectionTypes.HUBSPOT, ConnectionTypes.DYNAMICS],
				user
			).filter(connectionType => hasFeatureAccess(user, [`${connectionType.toUpperCase()}_LIST_IMPORT`], null));
		case TaskTypes.WEB_SUBMISSION:
			return filterConnectionList(
				[
					ConnectionTypes.SALESFORCE,
					ConnectionTypes.MARKETO,
					ConnectionTypes.PARDOT,
					ConnectionTypes.ELOQUA,
					ConnectionTypes.HUBSPOT,
					ConnectionTypes.DYNAMICS,
				],
				user
			).filter(connectionType => {
				if (
					connectionType === ConnectionTypes.SALESFORCE &&
					(hasFeatureAccess(user, ['W2L_MARKETO'], null) ||
						hasFeatureAccess(user, ['SF_ONLY_PREVENTION'], null))
				) {
					return true;
				}

				if (connectionType === ConnectionTypes.MARKETO && hasFeatureAccess(user, ['W2L_MARKETO'], null)) {
					return true;
				}

				return hasFeatureAccess(user, [`${connectionType.toUpperCase()}_W2L`], null);
			});
		case TaskTypes.DATA_QUALITY_SCORE:
			return filterConnectionList(
				[
					ConnectionTypes.SALESFORCE,
					ConnectionTypes.ELOQUA,
					ConnectionTypes.MARKETO,
					ConnectionTypes.HUBSPOT,
					ConnectionTypes.DYNAMICS,
				],
				user
			);
		default:
			return [];
	}
}

export function getApplicationType(task: WebSubmissionTask) {
	if (task.application !== ApplicationTypes.WEBHOOK) {
		return 'Form Submission';
	}

	if (
		task.connection.connection_type === ConnectionTypes.MARKETO ||
		(task.connection.connection_type === ConnectionTypes.SALESFORCE &&
			task.secondary_connection &&
			task.secondary_connection.connection_type === ConnectionTypes.MARKETO)
	) {
		return 'Marketo Webhook';
	}

	if (task.connection.connection_type === ConnectionTypes.SALESFORCE && !task.secondary_connection) {
		return 'Salesforce Trigger';
	}

	if (
		task.connection.connection_type === ConnectionTypes.ELOQUA &&
		task.secondary_connection &&
		task.secondary_connection.connection_type === ConnectionTypes.SALESFORCE
	) {
		return 'Eloqua Canvas with Salesforce';
	}

	if (task.connection.connection_type === ConnectionTypes.ELOQUA && !task.secondary_connection) {
		return 'Eloqua Canvas';
	}

	if (task.connection.connection_type === ConnectionTypes.HUBSPOT && !task.form_submission) {
		return 'HubSpot Trigger';
	}

	if (task.connection.connection_type === ConnectionTypes.DYNAMICS && !task.form_submission) {
		return 'Dynamics Webhook';
	}

	return 'Form Submission';
}

export function getChildObjects(task: MassUpdate) {
	return task.child_objects?.map(childObject => childObject.split('::')[0]) ?? [];
}

export function getCredits(record: { enrichment_credits_used: Record<string, number> | null }) {
	const credits = { ...(record.enrichment_credits_used || {}) };
	if ('NORMALIZATION' in credits) {
		delete credits.NORMALIZATION;
	}
	return credits;
}

export function getTotalCredits(record: { enrichment_credits_used: Record<string, number> | null }) {
	return Object.values(getCredits(record)).reduce((sum, value) => sum + value, 0);
}
