import { createTheme } from '@mui/material/styles';
import { renderTimeViewClock } from '@mui/x-date-pickers-pro';

import GridNoResultsOverlay from './ui-kit/DataGrid/GridNoResultsOverlay';
import GridNoRowsOverlay from './ui-kit/DataGrid/GridNoRowsOverlay';
import DatePickerField from './ui-kit/DatePickerField/DatePickerField';

const RingLeadPrimaryColors = {
	main: '#006eb9',
	light: '#f4f6fc',
	dark: '#04529d',
};

const RingLeadSecondaryColors = {
	main: '#f10091',
	light: '#f14fa7',
	dark: '#8b0063',
};

const autofillBoxShadow = '0 0 0 30px white inset !important';

const muitheme = createTheme({
	palette: {
		action: {
			hover: RingLeadPrimaryColors.light,
		},
		background: {
			default: RingLeadPrimaryColors.light,
		},
		primary: RingLeadPrimaryColors,
		secondary: RingLeadSecondaryColors,
		error: RingLeadSecondaryColors,
		prevent: '#322893',
		cleanse: '#0072BF',
		enrich: '#722282',
	},
});

const theme = createTheme(muitheme, {
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					'*::-webkit-scrollbar': {
						backgroundColor: '#fff',
						width: '16px',
					},
					'*::-webkit-scrollbar-track': {
						backgroundColor: '#fff',
					},
					'*::-webkit-scrollbar-track:hover': {
						backgroundColor: '#f4f4f4',
					},
					'*::-webkit-scrollbar-thumb': {
						backgroundColor: '#babac0',
						borderRadius: '16px',
						minHeight: '40px',
						border: '5px solid #fff',
					},
					'*::-webkit-scrollbar-thumb:hover': {
						backgroundColor: '#a0a0a5',
						borderRadius: '16px',
						border: '4px solid #f4f4f4',
					},
					html: {
						color: muitheme.palette.text.primary,
						fontFamily: muitheme.typography.fontFamily,
						fontWeight: muitheme.typography.body2.fontWeight,
						lineHeight: muitheme.typography.body2.lineHeight,
						letterSpacing: muitheme.typography.body2.letterSpacing,
					},
					'input:focus::-webkit-contacts-auto-fill-button': {
						opacity: 0,
					},
					'input::-webkit-clear-button': {
						display: 'none',
					},
					'input:-webkit-autofill': {
						'-webkit-box-shadow': autofillBoxShadow,
					},
					'input:-webkit-autofill:hover': {
						'-webkit-box-shadow': autofillBoxShadow,
					},
					'input:-webkit-autofill:focus': {
						'-webkit-box-shadow': autofillBoxShadow,
					},
					'input:-webkit-autofill:active': {
						'-webkit-box-shadow': autofillBoxShadow,
					},
					p: {
						marginBlockStart: 0,
						marginBlockEnd: 0,
					},
				},
			},
		},
		MuiAutocomplete: {
			defaultProps: {
				size: 'small',
				ChipProps: {
					color: 'primary',
				},
			},
		},
		MuiBreadcrumbs: {
			styleOverrides: {
				root: {
					fontSize: '1rem',
					flex: '1 1 auto',
					color: muitheme.palette.primary.light,
				},
				li: {
					display: 'inline-block',
					// fontSize: '1rem',
					maxWidth: '22em',
					whiteSpace: 'nowrap',
					textOverflow: 'ellipsis',
					overflow: 'hidden',
					fontWeight: 'bold',
					textDecoration: 'none',
					'&:last': {
						fontWeight: 'bolder',
					},
					'& > a': {
						color: muitheme.palette.primary.light,
						textDecoration: 'none',
					},
				},
			},
		},
		MuiInputBase: {
			defaultProps: {
				disableInjectingGlobalStyles: true,
			},
		},
		MuiOutlinedInput: {
			defaultProps: {
				margin: 'dense',
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					padding: 12,
				},
				sizeSmall: {
					padding: '4px 12px',
					'&.MuiTableCell-paddingCheckbox': {
						padding: '0 8px 0 12px',
					},
					'&.MuiTableCell-paddingNone': {
						padding: 0,
					},
				},
				paddingCheckbox: {
					padding: '0 8px 0 12px',
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				select: {
					'&:focus': {
						backgroundColor: 'transparent',
					},
				},
			},
		},
		MuiDatePicker: {
			defaultProps: {
				slots: {
					textField: DatePickerField,
					openPickerButton: () => null,
				},
			},
		},
		MuiDateTimePicker: {
			defaultProps: {
				slots: {
					textField: DatePickerField,
					openPickerButton: () => null,
				},
				viewRenderers: {
					hours: renderTimeViewClock,
					minutes: renderTimeViewClock,
					seconds: renderTimeViewClock,
				},
			},
		},
		MuiTimePicker: {
			defaultProps: {
				slots: {
					textField: DatePickerField,
					openPickerButton: () => null,
				},
				viewRenderers: {
					hours: renderTimeViewClock,
					minutes: renderTimeViewClock,
					seconds: renderTimeViewClock,
				},
			},
		},
		MuiDateRangePicker: {
			defaultProps: {
				slotProps: {
					field: {
						readOnly: true,
					},
				},
			},
		},
		MuiDataGrid: {
			defaultProps: {
				columnHeaderHeight: 48,
				rowHeight: 48,
				disableColumnReorder: true,
				disableColumnResize: true,
				disableColumnMenu: true,
				disableColumnFilter: true,
				disableSelectionOnClick: true,
				disableDensitySelector: true,
				hideFooterRowCount: true,
				hideFooterSelectedRowCount: true,
				disableRowSelectionOnClick: true,
				slots: {
					noRowsOverlay: GridNoRowsOverlay,
					noResultsOverlay: GridNoResultsOverlay,
				},
				slotProps: {
					rowReorderIcon: {
						fontSize: 'medium',
					},
				},
			},
			styleOverrides: {
				root: {
					'& .MuiDataGrid-actionsCell': {
						gridGap: 0,
						'& .MuiSvgIcon-root': {
							fontSize: 'inherit',
						},
						'& .MuiIconButton-root[aria-haspopup*="menu"]': {
							padding: muitheme.spacing(1),
							'& .MuiSvgIcon-root': {
								fontSize: 24,
								color: muitheme.palette.primary.main,
							},
						},
					},
					'& .MuiDataGrid-row--editing': {
						boxShadow: 'none',
					},
					'& .MuiDataGrid-cell--editing': {
						boxShadow: 'none !important',
					},
				},
				columnHeaders: {
					backgroundColor: muitheme.palette.primary.light,
				},
				columnHeader: {
					'&:focus': {
						outline: 'none',
					},
					'&:focus-within': {
						outline: 'none',
					},
				},
				pinnedRows: {
					backgroundColor: muitheme.palette.common.white,
				},
				columnSeparator: {
					display: 'none',
				},
				cell: {
					'&:focus': {
						outline: 'none',
					},
					'&:focus-within': {
						outline: 'none !important',
					},
				},
				footerContainer: {
					padding: muitheme.spacing(0, 2),
				},
			},
		},
	},
});

export default theme;
