import { createSlice } from '@reduxjs/toolkit';

import { taskApi } from './task';

type TasksState = {
	updating: boolean;
};

// TMP SOLUTION!!! for new stack use only data from mutation hook
const tasksSlice = createSlice({
	name: 'tasks',
	initialState: { updating: false } as TasksState,
	reducers: {},
	extraReducers: builder => {
		builder.addMatcher(taskApi.endpoints.editTask.matchPending, state => {
			state.updating = true;
		});
		builder.addMatcher(taskApi.endpoints.editTask.matchFulfilled, state => {
			state.updating = false;
		});
		builder.addMatcher(taskApi.endpoints.editTask.matchRejected, state => {
			state.updating = false;
		});
	},
});

export default tasksSlice.reducer;
