export const RoutingWorkFlowConstants = {
	WORKFLOW_REQUEST: 'WORKFLOW_REQUEST',
	WORKFLOW_LIST_SUCCESS: 'WORKFLOW_LIST_SUCCESS',
	WORKFLOW_LIST_FAIL: 'WORKFLOW_LIST_FAIL',
	WORKFLOW_CREATE: 'WORKFLOW_CREATE',
	WORKFLOW_CREATE_SUCCESS: 'WORKFLOW_CREATE_SUCCESS',
	WORKFLOW_CREATE_FAIL: 'WORKFLOW_CREATE_FAIL',
	WORKFLOW_BULK_CREATE: 'WORKFLOW_BULK_CREATE',
	WORKFLOW_BULK_CREATE_SUCCESS: 'WORKFLOW_BULK_CREATE_SUCCESS',
	WORKFLOW_BULK_CREATE_FAIL: 'WORKFLOW_BULK_CREATE_FAIL',
	WORKFLOW_RETRIEVE_SUCCESS: 'WORKFLOW_RETRIEVE_SUCCESS',
	WORKFLOW_RETRIEVE_FAIL: 'WORKFLOW_RETRIEVE_FAIL',
	WORKFLOW_UPDATE_SUCCESS: 'WORKFLOW_UPDATE_SUCCESS',
	WORKFLOW_UPDATE_FAIL: 'WORKFLOW_UPDATE_FAIL',
	WORKFLOW_DELETE_SUCCESS: 'WORKFLOW_DELETE_SUCCESS',
	WORKFLOW_DELETE_FAIL: 'WORKFLOW_DELETE_FAIL',
	ETM_FIELDS_REQUEST: 'ETM_FIELDS_REQUEST',
	ETM_FIELDS_SUCCESS: 'ETM_FIELDS_SUCCESS',
	ETM_FIELDS_FAIL: 'ETM_FIELDS_FAIL',
};

export const RoutingWorkFlowScenarioConstants = {
	WORKFLOW_SCENARIO_LIST_REQUEST: 'WORKFLOW_SCENARIO_LIST_REQUEST',
	WORKFLOW_SCENARIO_LIST_SUCCESS: 'WORKFLOW_SCENARIO_LIST_SUCCESS',
	WORKFLOW_SCENARIO_LIST_FAIL: 'WORKFLOW_SCENARIO_LIST_FAIL',
	WORKFLOW_SCENARIO_CREATE_SUCCESS: 'WORKFLOW_SCENARIO_CREATE_SUCCESS',
	WORKFLOW_SCENARIO_CREATE_FAIL: 'WORKFLOW_SCENARIO_CREATE_FAIL',
	WORKFLOW_SCENARIO_UPDATE_SUCCESS: 'WORKFLOW_SCENARIO_UPDATE_SUCCESS',
	WORKFLOW_SCENARIO_UPDATE_FAIL: 'WORKFLOW_SCENARIO_UPDATE_FAIL',
	WORKFLOW_SCENARIO_COPY_SUCCESS: 'WORKFLOW_SCENARIO_COPY_SUCCESS',
	WORKFLOW_SCENARIO_COPY_FAIL: 'WORKFLOW_SCENARIO_COPY_FAIL',
	WORKFLOW_SCENARIO_DELETE_SUCCESS: 'WORKFLOW_SCENARIO_DELETE_SUCCESS',
	WORKFLOW_SCENARIO_DELETE_FAIL: 'WORKFLOW_SCENARIO_DELETE_FAIL',
};

export const RoutingWorkFlowScenarioActionConstants = {
	WORKFLOW_SCENARIO_ACTION_CREATE_REQUEST: 'WORKFLOW_SCENARIO_ACTION_CREATE_REQUEST',
	WORKFLOW_SCENARIO_ACTION_CREATE_SUCCESS: 'WORKFLOW_SCENARIO_ACTION_CREATE_SUCCESS',
	WORKFLOW_SCENARIO_ACTION_CREATE_FAIL: 'WORKFLOW_SCENARIO_ACTION_CREATE_FAIL',
	WORKFLOW_SCENARIO_ACTION_UPDATE_SUCCESS: 'WORKFLOW_SCENARIO_ACTION_UPDATE_SUCCESS',
	WORKFLOW_SCENARIO_ACTION_UPDATE_FAIL: 'WORKFLOW_SCENARIO_ACTION_UPDATE_FAIL',
	WORKFLOW_SCENARIO_ACTION_DELETE_SUCCESS: 'WORKFLOW_SCENARIO_ACTION_DELETE_SUCCESS',
	WORKFLOW_SCENARIO_ACTION_DELETE_FAIL: 'WORKFLOW_SCENARIO_ACTION_DELETE_FAIL',
};

export const RoutingWorkFlowScenarioCriteriaConstants = {
	WORKFLOW_SCENARIO_CRITERIA_CREATE_SUCCESS: 'WORKFLOW_SCENARIO_CRITERIA_CREATE_SUCCESS',
	WORKFLOW_SCENARIO_CRITERIA_CREATE_FAIL: 'WORKFLOW_SCENARIO_CRITERIA_CREATE_FAIL',
	WORKFLOW_SCENARIO_CRITERIA_UPDATE_SUCCESS: 'WORKFLOW_SCENARIO_CRITERIA_UPDATE_SUCCESS',
	WORKFLOW_SCENARIO_CRITERIA_UPDATE_FAIL: 'WORKFLOW_SCENARIO_CRITERIA_UPDATE_FAIL',
	WORKFLOW_SCENARIO_CRITERIA_DELETE_SUCCESS: 'WORKFLOW_SCENARIO_CRITERIA_DELETE_SUCCESS',
	WORKFLOW_SCENARIO_CRITERIA_DELETE_FAIL: 'WORKFLOW_SCENARIO_CRITERIA_DELETE_FAIL',
	WORKFLOW_SCENARIO_CRITERIA_TMP_UPDATE: 'WORKFLOW_SCENARIO_CRITERIA_TMP_UPDATE',
	WORKFLOW_SCENARIO_SLA_TMP_UPDATE: 'WORKFLOW_SCENARIO_SLA_TMP_UPDATE',
	WORKFLOW_SCENARIO_OPP_TMP_UPDATE: 'WORKFLOW_SCENARIO_OPP_TMP_UPDATE',
};

export const RoundRobinConstants = {
	ROUND_ROBIN_FETCHING: 'ROUND_ROBIN_FETCHING',
	ROUND_ROBIN_LIST_SUCCESS: 'ROUND_ROBIN_LIST_SUCCESS',
	ROUND_ROBIN_LIST_FAIL: 'ROUND_ROBIN_LIST_FAIL',
	ROUND_ROBIN_CREATE: 'ROUND_ROBIN_CREATE',
	ROUND_ROBIN_BULK_CREATE: 'ROUND_ROBIN_BULK_CREATE',
	ROUND_ROBIN_CREATE_SUCCESS: 'ROUND_ROBIN_CREATE_SUCCESS',
	ROUND_ROBIN_CREATE_FAIL: 'ROUND_ROBIN_CREATE_FAIL',
	ROUND_ROBIN_RETRIEVE_SUCCESS: 'ROUND_ROBIN_RETRIEVE_SUCCESS',
	ROUND_ROBIN_RETRIEVE_FAIL: 'ROUND_ROBIN_RETRIEVE_FAIL',
	ROUND_ROBIN_UPDATE_SUCCESS: 'ROUND_ROBIN_UPDATE_SUCCESS',
	ROUND_ROBIN_UPDATE_FAIL: 'ROUND_ROBIN_UPDATE_SUCCESS',
	ROUND_ROBIN_DELETE_SUCCESS: 'ROUND_ROBIN_DELETE_SUCCESS',
	ROUND_ROBIN_DELETE_FAIL: 'ROUND_ROBIN_DELETE_FAIL',
	ROUND_ROBIN_USER_UPDATE_SUCCESS: 'ROUND_ROBIN_USER_UPDATE_SUCCESS',
	ROUND_ROBIN_USER_UPDATE_FAIL: 'ROUND_ROBIN_USER_UPDATE_SUCCESS',
	ROUND_ROBIN_USER_ADD_SUCCESS: 'ROUND_ROBIN_USER_ADD_SUCCESS',
	ROUND_ROBIN_USER_ADD_FAIL: 'ROUND_ROBIN_USER_ADD_FAIL',
	ROUND_ROBIN_USER_DELETE_SUCCESS: 'ROUND_ROBIN_USER_DELETE_SUCCESS',
	ROUND_ROBIN_USER_DELETE_FAIL: 'ROUND_ROBIN_USER_DELETE_FAIL',
	ROUND_ROBIN_BULK_CREATE_SUCCESS: 'ROUND_ROBIN_BULK_CREATE_SUCCESS',
	ROUND_ROBIN_BULK_CREATE_FAIL: 'ROUND_ROBIN_BULK_CREATE_FAIL',
};

export const RoutingLicensingConstants = {
	ROUTING_LICENSED_USER_LIST_REQUEST: 'ROUTING_LICENSED_USER_LIST_REQUEST',
	ROUTING_LICENSED_USER_LIST_SUCCESS: 'ROUTING_LICENSED_USER_LIST_SUCCESS',
	ROUTING_LICENSED_USER_LIST_FAIL: 'ROUTING_LICENSED_USER_LIST_FAIL',
	ROUTING_LICENSED_USER_UPDATE_SUCCESS: 'ROUTING_LICENSED_USER_UPDATE_SUCCESS',
	ROUTING_LICENSED_USER_UPDATE_FAIL: 'ROUTING_LICENSED_USER_UPDATE_FAIL',
	ROUTING_LICENSED_USER_DELETE_SUCCESS: 'ROUTING_LICENSED_USER_DELETE_SUCCESS',
	ROUTING_LICENSED_USER_DELETE_FAIL: 'ROUTING_LICENSED_USER_DELETE_FAIL',
	ROUTING_LICENSED_GET_AUTO_UPDATE_RECORD_SUCCESS: 'ROUTING_LICENSED_GET_AUTO_UPDATE_RECORD_SUCCESS',
	ROUTING_LICENSED_GET_AUTO_UPDATE_RECORD_FAIL: 'ROUTING_LICENSED_GET_AUTO_UPDATE_RECORD_FAIL',
	ROUTING_LICENSED_UPDATE_AUTO_UPDATE_RECORD_SUCCESS: 'ROUTING_LICENSED_UPDATE_AUTO_UPDATE_RECORD_SUCCESS',
	ROUTING_LICENSED_UPDATE_AUTO_UPDATE_RECORD_FAIL: 'ROUTING_LICENSED_UPDATE_AUTO_UPDATE_RECORD_FAIL',
};

export const RoutingTimeOffConstants = {
	ROUTING_TIME_OFF_LIST_REQUEST: 'ROUTING_TIME_OFF_LIST_REQUEST',
	ROUTING_TIME_OFF_LIST_SUCCESS: 'ROUTING_TIME_OFF_LIST_SUCCESS',
	ROUTING_TIME_OFF_LIST_FAIL: 'ROUTING_TIME_OFF_LIST_FAIL',
	ROUTING_TIME_OFF_CREATE_SUCCESS: 'ROUTING_TIME_OFF_CREATE_SUCCESS',
	ROUTING_TIME_OFF_CREATE_FAIL: 'ROUTING_TIME_OFF_CREATE_FAIL',
	ROUTING_TIME_OFF_UPDATE_OK: 'ROUTING_TIME_OFF_UPDATE_SUCCESS',
	ROUTING_TIME_OFF_UPDATE_FAIL: 'ROUTING_TIME_OFF_UPDATE_FAIL',
	ROUTING_TIME_OFF_DELETE_SUCCESS: 'ROUTING_TIME_OFF_DELETE_SUCCESS',
	ROUTING_TIME_OFF_DELETE_FAIL: 'ROUTING_TIME_OFF_DELETE_FAIL',
	ROUTING_TIME_OFF_GET_OK: 'ROUTING_TIME_OFF_GET_OK',
	ROUTING_TIME_OFF_GET_FAIL: 'ROUTING_TIME_OFF_GET_FAIL',
	ROUTING_TIME_OFF_EDIT_ITEM: 'ROUTING_TIME_OFF_EDIT_ITEM',
};

export const RoutingTeamConstants = {
	ROUTING_TEAM_LIST_REQUEST: 'ROUTING_TEAM_LIST_REQUEST',
	ROUTING_TEAM_LIST_SUCCESS: 'ROUTING_TEAM_LIST_SUCCESS',
	ROUTING_TEAM_LIST_FAIL: 'ROUTING_TEAM_LIST_FAIL',
	ROUTING_TEAM_EDIT_MODE: 'ROUTING_TEAM_EDIT_MODE',
	ROUTING_TEAM_MEMBER_EDIT_ITEM: 'ROUTING_TEAM_MEMBER_EDIT_ITEM',

	ROUTING_TEAM_CREATE_REQUEST: 'ROUTING_TEAM_CREATE_REQUEST',
	ROUTING_TEAM_CREATE_SUCCESS: 'ROUTING_TEAM_CREATE_SUCCESS',
	ROUTING_TEAM_CREATE_FAIL: 'ROUTING_TEAM_CREATE_FAIL',

	ROUTING_TEAM_UPDATE_SUCCESS: 'ROUTING_TEAM_UPDATE_SUCCESS',
	ROUTING_TEAM_UPDATE_FAIL: 'ROUTING_TEAM_UPDATE_FAIL',

	ROUTING_TEAM_DELETE_SUCCESS: 'ROUTING_TEAM_DELETE_SUCCESS',
	ROUTING_TEAM_DELETE_FAIL: 'ROUTING_TEAM_DELETE_FAIL',

	ROUTING_TEAM_MEMBER_CREATE_FAIL: 'ROUTING_TEAM_MEMBER_CREATE_FAIL',
	ROUTING_TEAM_MEMBER_CREATE_SUCCESS: 'ROUTING_TEAM_MEMBER_CREATE_SUCCESS',
	ROUTING_TEAM_MEMBER_UPDATE_FAIL: 'ROUTING_TEAM_MEMBER_UPDATE_FAIL',
	ROUTING_TEAM_MEMBER_UPDATE_SUCCESS: 'ROUTING_TEAM_MEMBER_UPDATE_SUCCESS',
	ROUTING_TEAM_MEMBER_DELETE_FAIL: 'ROUTING_TEAM_MEMBER_DELETE_FAIL',
	ROUTING_TEAM_MEMBER_DELETE_SUCCESS: 'ROUTING_TEAM_MEMBER_DELETE_SUCCESS',
};

export const FlowChartPopupConstants = {
	POPUP_SHOW: 'POPUP_SHOW',
	POPUP_CLOSE: 'POPUP_CLOSE',
	ROUTING_FLOW_DATA_SUCCESS: 'ROUTING_FLOW_DATA_SUCCESS',
	ROUTING_FLOW_DATA_FAIL: 'ROUTING_FLOW_DATA_FAIL',
	CHANGE_ELEMENT_SELECTION: 'CHANGE_ELEMENT_SELECTION',
	CHANGE_MATCH_TYPE: 'CHANGE_MATCH_TYPE',
	ROUTING_FLOW_DATA_UPDATE_SUCCESS: 'ROUTING_FLOW_DATA_UPDATE_SUCCESS',
	ROUTING_FLOW_DATA_UPDATE_FAIL: 'ROUTING_FLOW_DATA_UPDATE_FAIL',
};

export const CalendarStatusConstants = {
	CALENDAR_CONNECTED_STATUS_REQUEST: 'CALENDAR_CONNECTED_STATUS_REQUEST',
	CALENDAR_CONNECTED_STATUS_SUCCESS: 'CALENDAR_CONNECTED_STATUS_SUCCESS',
	CALENDAR_CONNECTED_STATUS_FAIL: 'CALENDAR_CONNECTED_STATUS_FAIL',
};

export const SalesLoftConstants = {
	LIST_CANDENCES_SUCCESS: 'LIST_CANDENCES_SUCCESS',
	LIST_CANDENCES_FAIL: 'LIST_CANDENCES_FAIL',
};

export const RULE_SEARCH_ACTIVE = 'RULE_SEARCH_ACTIVE';
export const RULE_SEARCH_RESET = 'RULE_SEARCH_RESET';

export const OutreachConstants = {
	LIST_SEQUENCES_SUCCESS: 'LIST_SEQUENCES_SUCCESS',
	LIST_SEQUENCES_FAIL: 'LIST_SEQUENCES_FAIL',
};

export const WORKFLOW_MODEL_TYPE_ETM = 'etm_model';
export const WORKFLOW_MODEL_TYPE_OTHER = 'other_model';
