import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import navbarReducer from 'app/navigation/services/navbarSlice';
import { createReduxHistoryContext } from 'redux-first-history';

import { apiKeyApi } from '../../admin/services/apiKey';
import { inviteApi } from '../../admin/services/invite';
import { permissionApi } from '../../admin/services/permission';
import { userApi } from '../../admin/services/user';
import { auditLogApi } from '../../audit_logs/services/auditLog';
import folderReducer from '../../audit_logs/services/folderSlice';
import { authApi } from '../../auth/services/auth';
import auth from '../../auth/services/authSlice';
import DiscoveryFiltersReducer from '../../dop/reducers/DiscoveryFiltersReducer';
import DiscoveryIEListsReducer from '../../dop/reducers/DiscoveryIEListsReducer';
import DiscoveryReducer from '../../dop/reducers/DiscoveryReducer';
import EnrichmentReducer from '../../dop/reducers/EnrichmentReducer';
import EventsConditionsReducer from '../../dop/reducers/EventsConditionsReducer';
import EventsDiscoverRelatedReducer from '../../dop/reducers/EventsDiscoverRelatedReducer';
import EventsMappingReducer from '../../dop/reducers/EventsMappingReducer';
import EventsReducer from '../../dop/reducers/EventsReducer';
import FieldsMappingReducer from '../../dop/reducers/FieldsMappingReducer';
import FiltersReducer from '../../dop/reducers/FiltersReducer';
import FormulaReducer from '../../dop/reducers/FormulaReducer';
import GroupFiltersReducer from '../../dop/reducers/GroupFiltersReducer';
import MasterRecordRulesReducer from '../../dop/reducers/MasterRecordRulesReducer';
import MatchingRulesReducer from '../../dop/reducers/MatchingRulesReducer';
import MeetingSchedulerConfigReducer from '../../dop/reducers/MeetingSchedulerConfigReducer';
import ModalReducer from '../../dop/reducers/ModalReducer';
import NormalizerReducer from '../../dop/reducers/NormalizerReducer';
import RecordsCountReducer from '../../dop/reducers/RecordsCountReducer';
import ReportReducer from '../../dop/reducers/ReportReducer';
import RequestLogReducer from '../../dop/reducers/RequestLogReducer';
import ResolutionConfigureFieldsReducer from '../../dop/reducers/ResolutionConfigureFieldsReducer';
import ResolutionReducer from '../../dop/reducers/ResolutionReducer';
import ResolutionSettingsReducer from '../../dop/reducers/ResolutionSettingsReducer';
import SurvivingFieldValueRulesReducer from '../../dop/reducers/SurvivingFieldValueRulesReducer';
import TaskConfigReducer from '../../dop/reducers/TaskConfigReducer';
import TaskReducer from '../../dop/reducers/TaskReducer';
import UpdateRulesReducer from '../../dop/reducers/UpdateRulesReducer';
import VisibilitySensorReducer from '../../dop/reducers/VisibilitySensorReducer';
import { dashboardApi } from '../../dop/services/dashboard';
import { discoveryIEListApi } from '../../dop/services/discoveryIEList';
import { enrichmentApi } from '../../dop/services/enrichment';
import { taskApi } from '../../dop/services/task';
import tasksReducer from '../../dop/services/taskSlice';
import { notificationApi } from '../../notification/services/notification';
import CalendarStatusReducer from '../../routing/reducers/CalendarStatusReducer';
import ETMAccountFieldsReducer from '../../routing/reducers/ETMAccountFieldsReducer';
import FlowChartPopupReducer from '../../routing/reducers/FlowChartPopupReducer';
import OutreachReducer from '../../routing/reducers/OutreachReducer';
import RoundRobinReducer from '../../routing/reducers/RoundRobinReducer';
import RoutingLicensingReducer from '../../routing/reducers/RoutingLicensingReducer';
import RoutingTeamReducer from '../../routing/reducers/RoutingTeamReducer';
import RoutingTimeOffReducer from '../../routing/reducers/RoutingTimeOffReducer';
import RoutingWorkflowReducer from '../../routing/reducers/RoutingWorkflowReducer';
import RoutingWorkflowScenarioReducer from '../../routing/reducers/RoutingWorkflowScenarioReducer';
import SalesLoftReducer from '../../routing/reducers/SalesLoftReducer';
import WorkflowRulesSearchReducer from '../../routing/reducers/WorkflowRulesSearchReducer';
import { routeApi } from '../../routing/services/route';
import { scoreModelApi } from '../../score/services/scoreModel';
import SegmentationMappingReducer from '../../segmentation/reducers/SegmentationMappingReducer';
import { segmentConfigApi } from '../../segmentation/services/segmentConfig';
import ConnectionFieldsReducer from '../../settings/reducers/ConnectionFieldsReducer';
import ConnectionReducer from '../../settings/reducers/ConnectionReducer';
import ConnectionTablesReducer from '../../settings/reducers/ConnectionTablesReducer';
import { connectionApi } from '../../settings/services/connection';
import { storageApi } from '../../settings/services/storage';
import CustomPackageReducer from '../../vendors/reducers/CustomPackageReducer';
import PackageFieldManipulationsReducer from '../../vendors/reducers/PackageFieldManipulationsReducer';
import PackageVendorApiFieldsReducer from '../../vendors/reducers/PackageVendorApiFieldsReducer';
import PackageVendorApiReducer from '../../vendors/reducers/PackageVendorApiReducer';
import { packageApi } from '../../vendors/services/package';
import packageReducer from '../../vendors/services/packageSlice';
import { vendorKeyApi } from '../../vendors/services/vendorKey';
import breadcrumbsSlice from '../components/PageContext/breadcrumbsSlice';
import browserHistory from '../History';
import { commonApi } from '../services/common';

import jwtMiddleware from './middlewares/jwtMiddleware';
import rtkQueryErrorMiddleware from './middlewares/rtkQueryErrorMiddleware';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({ history: browserHistory });

const store = configureStore({
	reducer: {
		[commonApi.reducerPath]: commonApi.reducer,
		[authApi.reducerPath]: authApi.reducer,
		[apiKeyApi.reducerPath]: apiKeyApi.reducer,
		[inviteApi.reducerPath]: inviteApi.reducer,
		[permissionApi.reducerPath]: permissionApi.reducer,
		[userApi.reducerPath]: userApi.reducer,
		[notificationApi.reducerPath]: notificationApi.reducer,
		auth,
		folder: folderReducer,
		navbar: navbarReducer,
		package: packageReducer,
		tasks: tasksReducer,
		[connectionApi.reducerPath]: connectionApi.reducer,
		[storageApi.reducerPath]: storageApi.reducer,
		[dashboardApi.reducerPath]: dashboardApi.reducer,
		[auditLogApi.reducerPath]: auditLogApi.reducer,
		[taskApi.reducerPath]: taskApi.reducer,
		[segmentConfigApi.reducerPath]: segmentConfigApi.reducer,
		[scoreModelApi.reducerPath]: scoreModelApi.reducer,
		[discoveryIEListApi.reducerPath]: discoveryIEListApi.reducer,
		[enrichmentApi.reducerPath]: enrichmentApi.reducer,
		[packageApi.reducerPath]: packageApi.reducer,
		[vendorKeyApi.reducerPath]: vendorKeyApi.reducer,
		[routeApi.reducerPath]: routeApi.reducer,
		ConnectionFieldsReducer,
		ConnectionReducer,
		ConnectionTablesReducer,
		CustomPackageReducer,
		EventsConditionsReducer,
		EventsDiscoverRelatedReducer,
		EventsMappingReducer,
		EventsReducer,
		PackageVendorApiReducer,
		PackageVendorApiFieldsReducer,
		PackageFieldManipulationsReducer,
		DiscoveryFiltersReducer,
		DiscoveryIEListsReducer,
		DiscoveryReducer,
		FieldsMappingReducer,
		FiltersReducer,
		FlowChartPopupReducer,
		FormulaReducer,
		GroupFiltersReducer,
		MasterRecordRulesReducer,
		MatchingRulesReducer,
		ModalReducer,
		NormalizerReducer,
		OutreachReducer,
		RecordsCountReducer,
		ReportReducer,
		RequestLogReducer,
		ResolutionConfigureFieldsReducer,
		ResolutionReducer,
		ResolutionSettingsReducer,
		RoundRobinReducer,
		RoutingLicensingReducer,
		RoutingTimeOffReducer,
		RoutingTeamReducer,
		RoutingWorkflowReducer,
		RoutingWorkflowScenarioReducer,
		SalesLoftReducer,
		SegmentationMappingReducer,
		EnrichmentReducer,
		SurvivingFieldValueRulesReducer,
		TaskConfigReducer,
		TaskReducer,
		UpdateRulesReducer,
		VisibilitySensorReducer,
		WorkflowRulesSearchReducer,
		MeetingSchedulerConfigReducer,
		CalendarStatusReducer,
		ETMAccountFieldsReducer,
		breadcrumbs: breadcrumbsSlice,
		router: routerReducer,
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware().concat([
			commonApi.middleware,
			authApi.middleware,
			apiKeyApi.middleware,
			inviteApi.middleware,
			permissionApi.middleware,
			userApi.middleware,
			notificationApi.middleware,
			connectionApi.middleware,
			storageApi.middleware,
			dashboardApi.middleware,
			segmentConfigApi.middleware,
			scoreModelApi.middleware,
			auditLogApi.middleware,
			taskApi.middleware,
			discoveryIEListApi.middleware,
			enrichmentApi.middleware,
			packageApi.middleware,
			vendorKeyApi.middleware,
			routeApi.middleware,
			jwtMiddleware,
			rtkQueryErrorMiddleware,
			routerMiddleware,
		]),
});

setupListeners(store.dispatch);

export const history = createReduxHistory(store);

export default store;
